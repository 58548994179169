import { useAuthStore } from '~/stores/auth';
import {
  INFO_MESSAGE_PAGE_ROUTE,
  INFO_MESSAGE_PAGE_PARAMS,
} from '~/constants/infoMessages';

export default defineNuxtRouteMiddleware(() => {
  const { getUserData } = useAuthStore();

  if (getUserData.loggedIn) {
      return navigateTo({ path: `${INFO_MESSAGE_PAGE_ROUTE}/${INFO_MESSAGE_PAGE_PARAMS.login}` });
  }
});
